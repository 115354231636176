<template>
  <router-view :key="$route.path"></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { GET_USER } from "@/core/services/store/users.module";
import { LOGOUT, SET_ACCOUNT_OBJECT } from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";
export default {
  name: "diapps",
  mounted() {
    // if (
    //   window.webpackHotUpdate ||
    //   (process.env.NODE_ENV !== "production" &&
    //     process.env.NODE_ENV !== "test" &&
    //     typeof console !== "undefined")
    // ) {
    // } else {
    //   location.href = location.href.replace("http://", "https://");
    // }

    if (this.currentUser) {
      this.$store
        .dispatch(GET_USER, this.currentUser._id)
        .then((user) => {
          this.$store.dispatch(SET_ACCOUNT_OBJECT, user);
        })
        .catch((err) => {
          this.$store.dispatch(LOGOUT);
          this.$bvToast.toast(
            typeof err !== "undefined"
              ? err.message
              : "Failed to fetch user account. Please reload page to try again.",
            {
              title: `Failed to create account`,
              variant: "danger",
              solid: true,
              appendToast: true,
            }
          );
        })
        .finally(() => {});
    }

    // console.log(this.currentUser.organisation);
    // if (
    //   this.currentUser &&
    //   this.currentUser.accountType === "developer" &&
    //   typeof this.currentUser.organisation === "undefined" &&
    //   this.$router.path !== "organisation"
    // ) {
    //   this.$router.push("/organisation");
    // }

    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Georama:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;800&display=swap");
/* 
*,
html {
  font-family: "Poppins", sans-serif !important;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Georama", sans-serif !important;
  font-weight: 700;
}
.btn-rounded {
  border-radius: 50px;
}
</style>
